@media screen and (max-width: 991px) {
  main {
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .sidebar-right {
    flex-basis: 100%;
    margin-left: 0;

    .sidebar {
      &__list {
        li {
          flex-basis: 31%;
          margin: 0 1% 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 5px 15px;
    background-color: #fff;

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(27deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-27deg);
              transform-origin: left;
            }
          }
        }
      }

      &__logo {
        height: 30px;
      }
    }
  }
  
  .sidebar-left {
    position: absolute;
    background-color: #fff;
    top: -20px;
    left: 50px;
    padding: 20px;
    margin: 0;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;

    &.active {
      top: 10px;
      opacity: 1;
      visibility: visible;
    }
    ul {
      color: #000;

      li {
        margin: 0 0 15px 0;
      }
    }
  }
  .sidebar-right {
    .sidebar {
      &__list {
        li {
          flex-basis: 48%
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {

  .sidebar-right {
    .sidebar {
      &__list {
        li {
          flex-basis: 100%;
          margin: 0 0 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    table {
      td {
        min-width: auto;
        padding: 8px 15px;
      }
    }
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
    ul, ol {
      padding-left: 0;
    }
  }
}