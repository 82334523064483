@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Barlow, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #EDF2F4;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 5px 0;

  .header {
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 17px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #ED1C24;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__logo {
      height: 60px;
      width: auto;

      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

main {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sidebar-left {
  width: 200px;
  margin: 0 25px 25px 0;
  padding-right: 15px;

  ul {
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    color: #ED1C24;
    
    li {
      margin-bottom: 8px;

      &.current {
        text-decoration: none;
      }
    }
    a {
      text-decoration: underline;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.sidebar-right {
  width: 270px;
  margin: 0 0 25px 25px;
}

aside {
  .sidebar {
    &__title {
      display: block;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      li {
        flex-basis: 100%;
        margin-bottom: 10px;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
        font-size: 18px;
        font-weight: 600;
        background-color: #fff;
        border-radius: 30px;

        &:hover {
          background-color: darken(#fff, 10%);
        }
      }
    }
  }
}

article {
  flex: 1;
}

.content {
  font-weight: 500;
  margin-bottom: 25px;

  & > * {
    margin-bottom: 15px;
  }
  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  a {
    color: #ED1C24;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
  table {
    max-width: 100%;
    border-collapse: collapse;
    font-weight: 600;
    margin: 0 auto;

    td {
      text-align: center;
      border: 1px solid #ED1C24;
      padding: 8px 50px;
      min-width: 65px;
    }
  }
}

footer {
  width: 100%;
  background-color: #fff;

  .footer {
    &__copyright {
      font-weight: 500;
      text-align: center;
      padding: 10px;
    }
  }
}

@import "media";