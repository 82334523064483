@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowmedium.woff2") format("woff2"), url("../fonts/Barlowmedium.woff") format("woff"), url("../fonts/Barlowmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowsemibold.woff2") format("woff2"), url("../fonts/Barlowsemibold.woff") format("woff"), url("../fonts/Barlowsemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowbold.woff2") format("woff2"), url("../fonts/Barlowbold.woff") format("woff"), url("../fonts/Barlowbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Barlow, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #EDF2F4;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 5px 0;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 17px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ED1C24;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__logo {
  height: 60px;
  width: auto;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
main {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.sidebar-left {
  width: 200px;
  margin: 0 25px 25px 0;
  padding-right: 15px;
}
.sidebar-left ul {
  list-style: none;
  font-size: 18px;
  font-weight: 600;
  color: #ED1C24;
}
.sidebar-left ul li {
  margin-bottom: 8px;
}
.sidebar-left ul li.current {
  text-decoration: none;
}
.sidebar-left ul a {
  text-decoration: underline;
}
.sidebar-left ul a:hover {
  text-decoration: none;
}
.sidebar-right {
  width: 270px;
  margin: 0 0 25px 25px;
}
aside .sidebar__title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
aside .sidebar__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
aside .sidebar__list li {
  flex-basis: 100%;
  margin-bottom: 10px;
}
aside .sidebar__list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 30px;
}
aside .sidebar__list a:hover {
  background-color: #e6e6e6;
}
article {
  flex: 1;
}
.content {
  font-weight: 500;
  margin-bottom: 25px;
}
.content > * {
  margin-bottom: 15px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 24px;
}
.content h2 {
  font-size: 22px;
}
.content h3 {
  font-size: 20px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content a {
  color: #ED1C24;
  text-decoration: underline;
}
.content a:hover {
  text-decoration: none;
}
.content table {
  max-width: 100%;
  border-collapse: collapse;
  font-weight: 600;
  margin: 0 auto;
}
.content table td {
  text-align: center;
  border: 1px solid #ED1C24;
  padding: 8px 50px;
  min-width: 65px;
}
footer {
  width: 100%;
  background-color: #fff;
}
footer .footer__copyright {
  font-weight: 500;
  text-align: center;
  padding: 10px;
}
@media screen and (max-width: 991px) {
  main {
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .sidebar-right {
    flex-basis: 100%;
    margin-left: 0;
  }
  .sidebar-right .sidebar__list li {
    flex-basis: 31%;
    margin: 0 1% 10px;
  }
}
@media screen and (max-width: 768px) {
  header {
    padding: 5px 15px;
    background-color: #fff;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(27deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-27deg);
    transform-origin: left;
  }
  header .header__logo {
    height: 30px;
  }
  .sidebar-left {
    position: absolute;
    background-color: #fff;
    top: -20px;
    left: 50px;
    padding: 20px;
    margin: 0;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .sidebar-left.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  .sidebar-left ul {
    color: #000;
  }
  .sidebar-left ul li {
    margin: 0 0 15px 0;
  }
  .sidebar-right .sidebar__list li {
    flex-basis: 48%;
  }
}
@media screen and (max-width: 560px) {
  .sidebar-right .sidebar__list li {
    flex-basis: 100%;
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 480px) {
  .content table td {
    min-width: auto;
    padding: 8px 15px;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
}
