@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowmedium.woff2") format("woff2"),
      url("../fonts/Barlowmedium.woff") format("woff"),
      url("../fonts/Barlowmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowsemibold.woff2") format("woff2"),
      url("../fonts/Barlowsemibold.woff") format("woff"),
      url("../fonts/Barlowsemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlowbold.woff2") format("woff2"),
      url("../fonts/Barlowbold.woff") format("woff"),
      url("../fonts/Barlowbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}